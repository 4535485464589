<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-button variant="primary" v-b-modal.modal-input-permintaan-obat>
          <CIcon name="cil-plus" /> Tambah Data
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Per Halaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          label="Cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Ketik disini untuk mencari ..."
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >Hapus</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(actions)="item">
            <b-button
              variant="warning"
              class="mr-1"
              v-c-tooltip.hover.click="'Edit Data'"
              v-b-modal.modal-edit-permintaan-obat
              @click="is_data = item.item"
              ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
            >

            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="'Hapus Data'"
              v-b-modal.modal-delete-permintaan-obat
              @click="is_data = item.item"
              ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <modal-input
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :data_edit="is_data"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";
export default {
  components:{
    ModalInput,
    ModalDelete,
    ModalEdit,
  },
  props: [
  ],
  data() {
    return {
      is_data: [],
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_resep",
          label: "No Resep",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "dokter_resep",
          label: "Dokter Resep",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "obat",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jumlah",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai",
          label: "Aturan Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "satuan",
          label: "Satuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keterangan",
          label: "Keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    }
  },
  async mounted() {
    const vm = this
    vm.getDatas()
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas(){
      const vm = this
      try {
        vm.items = []
        vm.tableBusy = true;
        for (let i = 1; i <= 100; i++) {
          vm.items.push({
            no: i,
            tanggal: moment().add(i, 'months').add(i, 'hours').add(i*111, 'seconds').format('DD MMMM YYYY, HH:mm:ss'),
            no_resep:(i + i) * 15 % 11,
            dokter_resep: 'dokter ' + i,
            obat: 'diagnosa ' + i,
            jumlah: (i + i) * 9 % 7,
            aturan_pakai: 'aturan pakai ' + i,
            satuan: 'satuan ' + i,
            keterangan: 'keterangan ' + i,
          })
        }
        vm.totalRows = vm.items.length
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({
            variant: "danger",
            msg:err.message.toUpperCase(),
            showing: true,
          });
        } else {
          vm.triggerAlert({
            variant: "danger",
            msg:err.toUpperCase(),
            showing: true,
          });
        }
      } finally {
        vm.tableBusy = false;
      }
      
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>