<template>
  <div>
    <b-modal
      id="modal-input-rekam-medis"
      size="xl"
      centered
      title="S.O.A.P"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div class="">
        <h3>PEMERIKSAAN AWAL</h3>
        <CButton 
          color="primary"
          class="w-100 my-2 mb-3"
          v-b-modal.modal-odontogram
        >
          Odontogram
        </CButton>
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TANGGAL PEMERIKSAAN <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              id="a_tanggal_pemeriksaan_a"
              format="DD-MM-YYYY"
              v-model="$v.is_data.a_tanggal_pemeriksaan_a.$model"
              :state="checkIfValid('a_tanggal_pemeriksaan_a')"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              KELUHAN <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="textarea"
              :state="checkIfValid('a_keluhan_a')"
              v-model="$v.is_data.a_keluhan_a.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              ANAMNESA <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="textarea"
              :state="checkIfValid('a_anamnesa_a')"
              v-model="$v.is_data.a_anamnesa_a.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              RIWAYAT ALERGI
            </template>
            <div class="">
              <multiselect
                id="a_riwayat_alergi_makanan_a"
                v-model="$v.is_data.a_riwayat_alergi_makanan_a.$model"
                :options="list_riwayat_alergi_makanan"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="value"
                placeholder="-- Pilih Alergi Makanan --"
                size="sm"
              ></multiselect>
              <multiselect
                id="a_riwayat_alergi_udara_a"
                class="mt-2"
                v-model="$v.is_data.a_riwayat_alergi_udara_a.$model"
                :options="list_riwayat_alergi_udara"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="value"
                placeholder="-- Pilih Alergi Udara --"
                size="sm"
              ></multiselect>
              <multiselect
                id="a_riwayat_alergi_obat_a"
                class="mt-2"
                v-model="$v.is_data.a_riwayat_alergi_obat_a.$model"
                :options="list_riwayat_alergi_obat"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="value"
                placeholder="-- Pilih Alergi Obat --"
                size="sm"
              ></multiselect>
                <!-- :class="{invalid: !checkIfValid('a_riwayat_alergi_makanan_a')}" -->
            </div>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              PROGNOSA
            </template>
            <multiselect
              id="a_prognosa_a"
              v-model="$v.is_data.a_prognosa_a.$model"
              :options="list_prognosa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Prognosa --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TERAPI OBAT <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="textarea"
              :state="checkIfValid('a_terapi_obat_a')"
              v-model="$v.is_data.a_terapi_obat_a.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TERAPI NON OBAT <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="textarea"
              :state="checkIfValid('a_terapi_non_obat_a')"
              v-model="$v.is_data.a_terapi_non_obat_a.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              BMHP <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="textarea"
              :state="checkIfValid('a_bmhp_a')"
              v-model="$v.is_data.a_bmhp_a.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TACC
            </template>
            <multiselect
              id="a_tacc_a"
              v-model="$v.is_data.a_tacc_a.$model"
              :options="list_tacc"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih TACC --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              ALASAN TACC <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="textarea"
              :state="checkIfValid('a_alasan_tacc_a')"
              v-model="$v.is_data.a_alasan_tacc_a.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              STATUS PULANG <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_status_pulang_a"
              v-model="$v.is_data.a_status_pulang_a.$model"
              :options="list_status_pulang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Status Pulang --"
              size="sm"
            ></multiselect>
              <!-- :class="{invalid: !checkIfValid('a_status_pulang_a')}" -->
          </b-form-group>
        </b-form>
        <hr/>
        <h3>PEMERIKSAAN FISIK</h3>
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              LUAR
            </template>
            <b-row>
              <b-col cols="4">
                <label for="tinggi_badan">TINGGI BADAN</label>
                <b-form-input
                  id="tinggi_badan"
                  type="text"
                  :state="checkIfValid('a_tinggi_badan_a')"
                  v-model="$v.is_data.a_tinggi_badan_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <label for="berat_badan">BERAT BADAN</label>
                <b-form-input
                  id="berat_badan"
                  type="text"
                  :state="checkIfValid('a_berat_badan_a')"
                  v-model="$v.is_data.a_berat_badan_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <label for="lingkar_perut">LINGKAR PERUT</label>
                <b-form-input
                  id="lingkar_perut"
                  type="text"
                  :state="checkIfValid('a_lingkar_perut_a')"
                  v-model="$v.is_data.a_lingkar_perut_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for="suhu">SUHU</label>
                <b-form-input
                  id="suhu"
                  type="text"
                  :state="checkIfValid('a_suhu_a')"
                  v-model="$v.is_data.a_suhu_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for="imt">IMT</label>
                <b-form-input
                  id="imt"
                  type="text"
                  :state="checkIfValid('a_imt_a')"
                  v-model="$v.is_data.a_imt_a.$model"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              DALAM
            </template>
            <b-row>
              <b-col cols="4">
                <label for="tekanan_darah">TEKANAN DARAH</label>
                <b-form-input
                  id="tekanan_darah"
                  type="text"
                  :state="checkIfValid('a_tekanan_darah_a')"
                  v-model="$v.is_data.a_tekanan_darah_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <label for="sistole">SISTOLE</label>
                <b-form-input
                  id="sistole"
                  type="text"
                  :state="checkIfValid('a_sistole_a')"
                  v-model="$v.is_data.a_sistole_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <label for="diastole">DIASTOLE</label>
                <b-form-input
                  id="diastole"
                  type="text"
                  :state="checkIfValid('a_diastole_a')"
                  v-model="$v.is_data.a_diastole_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for="respiratory_rate">RESPIRATORY RATE</label>
                <b-form-input
                  id="respiratory_rate"
                  type="text"
                  :state="checkIfValid('a_respiratory_rate_a')"
                  v-model="$v.is_data.a_respiratory_rate_a.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for="heart_rate">HEART RATE</label>
                <b-form-input
                  id="heart_rate"
                  type="text"
                  :state="checkIfValid('a_heart_rate_a')"
                  v-model="$v.is_data.a_heart_rate_a.$model"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              KESADARAN
            </template>
            <div class="d-flex align-content-center flex-wrap">
              <div class="form-check">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_kesadaran_a" id="a_kesadaran_a_1" checked v-model="is_data.a_kesadaran_a" value="COMPOS MENTIS">
                <label class="form-check-label" for="a_kesadaran_a_1">
                  COMPOS
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_kesadaran_a" id="a_kesadaran_a_2" checked v-model="is_data.a_kesadaran_a" value="SOMNOLENCE">
                <label class="form-check-label" for="a_kesadaran_a_2">
                  SOMNOLENCE
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_kesadaran_a" id="a_kesadaran_a_3" checked v-model="is_data.a_kesadaran_a" value="SOPOR">
                <label class="form-check-label" for="a_kesadaran_a_3">
                  SOPOR
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_kesadaran_a" id="a_kesadaran_a_4" checked v-model="is_data.a_kesadaran_a" value="COMA">
                <label class="form-check-label" for="a_kesadaran_a_4">
                  COMA
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TENAGA MEDIS (PCARE) <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_tenaga_medis_pcare_a"
              v-model="$v.is_data.a_tenaga_medis_pcare_a.$model"
              :options="list_tenaga_medis_pcare"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Tenaga Medis PCARE --"
              size="sm"
            ></multiselect>
              <!-- :class="{invalid: !checkIfValid('a_tenaga_medis_pcare_a')}" -->
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TENAGA MEDIS
            </template>
            <multiselect
              id="a_tenaga_medis_a"
              v-model="$v.is_data.a_tenaga_medis_a.$model"
              :options="list_tenaga_medis"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Tenaga Medis --"
              size="sm"
            ></multiselect>
          </b-form-group>
        </b-form>
      </div>
      <!-- <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Asuransi <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_asuransi')"
            type="text"
            v-model="$v.is_data.nama_asuransi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tipe <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="$v.is_data.tipe_asuransi.$model"
            :state="checkIfValid('tipe_asuransi')"
            :options="options"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
            <template v-slot:label>
                Jenis Harga <span class="text-danger">*</span>
            </template>
            <b-form-select 
                v-model="$v.is_data.ms_harga_id.$model"
                :state="checkIfValid('ms_harga_id')"
                :options="optionsHarga"
            ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Telp <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('no_telepon_asuransi')"
            type="number"
            v-model="$v.is_data.no_telepon_asuransi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Contact Person <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_nama')"
            type="text"
            v-model="$v.is_data.cp_nama.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Alamat <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_alamat')"
            type="text"
            v-model="$v.is_data.cp_alamat.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Telp <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_telepon')"
            type="number"
            v-model="$v.is_data.cp_telepon.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Mobile <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_hp')"
            type="number"
            v-model="$v.is_data.cp_hp.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Email <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_email')"
            type="email"
            v-model="$v.is_data.cp_email.$model"
          ></b-form-input>
        </b-form-group>
      </b-form> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-rekam-medis')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
    <modal-odontogram
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import _ from "lodash"
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/id';
import "vue2-datepicker/index.css";
import moment from 'moment';
moment.locale('id');
import 'moment/locale/id';
import ModalOdontogram from "./modalOdontogram.vue";
export default {
  name: "modalInput",
  components:{
    DatePicker,
    Multiselect,
    ModalOdontogram,
  },
  props: [],
  data() {
    return {
      list_riwayat_alergi_makanan: [
        {value: 'Seafood', text: 'Seafood'},
        {value: 'Gandum', text: 'Gandum'},
        {value: 'Susu Sapi', text: 'Susu Sapi'},
        {value: 'Kacang - Kacangan', text: 'Kacang - Kacangan'},
        {value: 'Makanan Lain', text: 'Makanan Lain'},
      ],
      list_riwayat_alergi_udara: [
        {value: 'Udara Dingin', text: 'Udara Dingin'},
        {value: 'Udara Panas', text: 'Udara Panas'},
        {value: 'Udara Kotor', text: 'Udara Kotor'},
      ],
      list_riwayat_alergi_obat: [
        {value: 'Antibiotik', text: 'Antibiotik'},
        {value: 'Antiinflamasi', text: 'Antiinflamasi'},
        {value: 'Non Steroid', text: 'Non Steroid'},
        {value: 'Aspirin', text: 'Aspirin'},
        {value: 'Kortikosteroid', text: 'Kortikosteroid'},
        {value: 'Insulin', text: 'Insulin'},
        {value: 'Obat - Obatan lain', text: 'Obat - Obatan lain'},
      ],
      list_prognosa: [
        {value: 'SANAM (sembuh)', text: 'SANAM (sembuh)'},
        {value: 'BONAM (baik)', text: 'BONAM (baik)'},
        {value: 'MALAM (buruk / jelek)', text: 'MALAM (buruk / jelek)'},
        {value: 'DUBIA AS SANAM (tidak tentu / ragu - ragu, cenderung sembuk / baik)', text: 'DUBIA AS SANAM (tidak tentu / ragu - ragu, cenderung sembuk / baik)'},
        {value: 'DUBIA AS MALAM (tidak tentu / ragu - ragu, cenderung sembuk / baik)', text: 'DUBIA AS MALAM (tidak tentu / ragu - ragu, cenderung sembuk / baik)'},
      ],
      list_tacc: [
        {value: 'Time', text: 'Time'},
        {value: 'Age', text: 'Age'},
        {value: 'Complication', text: 'Complication'},
        {value: 'Comorbidity', text: 'Comorbidity'},
      ],
      list_status_pulang: [
        {value: 'Meninggal', text: 'Meninggal'},
        {value: 'Berobat Jalan', text: 'Berobat Jalan'},
        {value: 'Rujuk Vertikal', text: 'Rujuk Vertikal'},
        {value: 'Rujuk Horizontal', text: 'Rujuk Horizontal'},
        {value: 'Rujuk Internal', text: 'Rujuk Internal'},
      ],
      list_tenaga_medis_pcare: [
        {value: 'Tenaga Medis 288619', text: 'Tenaga Medis 288619'},
        {value: 'Tenaga Medis 378182', text: 'Tenaga Medis 378182'},
      ],
      list_tenaga_medis: [
        {value: 'dr.Siti Chidijah, SP.OG', text: 'dr.Siti Chidijah, SP.OG'},
        {value: 'dr.Kurnia MPD', text: 'dr.Kurnia MPD'},
        {value: 'dr.Nurul SPD', text: 'dr.Nurul SPD'},
      ],
      is_data: {
        //pemeriksaan awal
        a_tanggal_pemeriksaan_a: '',
        a_keluhan_a: '',
        a_anamnesa_a: '',
        a_riwayat_alergi_makanan_a: null,
        a_riwayat_alergi_udara_a: '',
        a_riwayat_alergi_obat_a: '',
        a_prognosa_a: '',
        a_terapi_obat_a: '',
        a_terapi_non_obat_a: '',
        a_bmhp_a: '',
        a_diagnosa_a: [],
        a_tacc_a: '',
        a_alasan_tacc_a: '',
        a_kasus_kill_a: '',
        a_pelayanan_non_kapitasi_a: [],
        a_status_pulang_a: '',
        a_kode_faskes_a: '',
        a_ppk_rujukan_a: '',
        a_spesialis_a: '',
        a_tanggal_rencana_berkunjung_a: '',
        
        //pemeriksaan fisik
        a_kesadaran_a: '',
        a_suhu_a: '',
        a_tinggi_badan_a: '',
        a_berat_badan_a: '',
        a_lingkar_perut_a: '',
        a_imt_a: '',
        a_tekanan_darah_a: '',
        a_sistole_a: '',
        a_diastole_a: '',
        a_respiratory_rate_a: '',
        a_heart_rate_a: '',
        a_tenaga_medis_pcare_a: '',
        a_tenaga_medis_a: '',
      },
      // },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      a_tanggal_pemeriksaan_a: { required },
      a_keluhan_a: { required },
      a_anamnesa_a: { required },
      a_riwayat_alergi_makanan_a: {  },
      a_riwayat_alergi_udara_a: {  },
      a_riwayat_alergi_obat_a: {  },
      a_prognosa_a: {  },
      a_terapi_obat_a: { required },
      a_terapi_non_obat_a: { required },
      a_bmhp_a: { required },
      a_diagnosa_a: { required },
      a_tacc_a: {  },
      a_alasan_tacc_a: { required },
      a_kasus_kill_a: { required },
      a_pelayanan_non_kapitasi_a: { required },
      a_status_pulang_a: { required },
      a_kode_faskes_a: { required },
      a_ppk_rujukan_a: { required },
      a_spesialis_a: { required },
      a_tanggal_rencana_berkunjung_a: { required },

      a_kesadaran_a: {  },
      a_suhu_a: { required },
      a_tinggi_badan_a: { required },
      a_berat_badan_a: { required },
      a_lingkar_perut_a: { required },
      a_imt_a: { required },
      a_tekanan_darah_a: { required },
      a_sistole_a: { required },
      a_diastole_a: { required },
      a_respiratory_rate_a: { required },
      a_heart_rate_a: { required },
      a_tenaga_medis_pcare_a: { required },
      a_tenaga_medis_a: {  },
    },
  },
  async mounted() {
    const vm = this
    vm.is_data.a_status_pulang_a = vm.list_status_pulang[vm.list_status_pulang.length-1]
    vm.is_data.a_tenaga_medis_pcare_a = vm.list_tenaga_medis_pcare[vm.list_tenaga_medis_pcare.length-1]
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      if(vm.busy){
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: "BERHASIL MENDAFTARKAN REKAM MEDIS",
          showing: true,
        });
        this.$bvModal.hide("modal-input-rekam-medis");
        this.resetModal();
      }else{
        axios
          .post(ipBackend + "msAsuransi/register", vm.is_data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN REKAM MEDIS",
                showing: true,
              });
              this.$bvModal.hide("modal-input-rekam-medis");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
      };
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
