<template>
  <div>
    <b-modal
      id="modal-odontogram"
      size="xl"
      centered
      title="Odontogram"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div class="">
        <div class="mx-5">
          <CImg
            src="https://pcarejkn.bpjs-kesehatan.go.id/eclaim/Content/images/odontogram.png"
            block
            class="mb-2 w-100"
          />
        </div>
        <b-form class="mt-4">
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Elemen Gigi <span class="text-danger">*</span>
            </template>
            <multiselect
              id="element_gigi"
              v-model="$v.is_data.element_gigi.$model"
              :options="list_element_gigi"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Elemen Gigi --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Diagnosa <span class="text-danger">*</span>
            </template>
            <multiselect
              id="diagnosa"
              v-model="$v.is_data.diagnosa.$model"
              :options="list_diagnosa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Diagnosa --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Prosedur <span class="text-danger">*</span>
            </template>
            <multiselect
              id="prosedur"
              v-model="$v.is_data.prosedur.$model"
              :options="list_prosedur"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Prosedur --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Occlusi <span class="text-danger">*</span>
            </template>
            <multiselect
              id="occilusi"
              v-model="$v.is_data.occilusi.$model"
              :options="list_occilusi"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Occlusi --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Torus Palatines <span class="text-danger">*</span>
            </template>
            <multiselect
              id="torus_palatines"
              v-model="$v.is_data.torus_palatines.$model"
              :options="list_torus_palatines"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Torus Palatines --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Torus Mandibularis <span class="text-danger">*</span>
            </template>
            <multiselect
              id="torus_mandibularis"
              v-model="$v.is_data.torus_mandibularis.$model"
              :options="list_torus_mandibularis"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Torus Mandibularis --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Palatum <span class="text-danger">*</span>
            </template>
            <multiselect
              id="palatum"
              v-model="$v.is_data.palatum.$model"
              :options="list_palatum"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Palatum --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Diastema <span class="text-danger">*</span>
            </template>
            <multiselect
              id="diastema"
              v-model="$v.is_data.diastema.$model"
              :options="list_diastema"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Diastema --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jelaskan <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="jelaskan"
              :state="checkIfValid('jelaskan')"
              v-model="$v.is_data.jelaskan.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Gigi Anomaly <span class="text-danger">*</span>
            </template>
            <multiselect
              id="gigi_anomaly"
              v-model="$v.is_data.gigi_anomaly.$model"
              :options="list_gigi_anomaly"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Gigi Anomaly --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Lain-lain <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="lain_lain"
              :state="checkIfValid('lain_lain')"
              v-model="$v.is_data.lain_lain.$model"
            ></b-form-textarea>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-odontogram')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash"
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import 'vue2-datepicker/locale/id';
// import "vue2-datepicker/index.css";
import moment from 'moment';
moment.locale('id');
import 'moment/locale/id';
export default {
  name: "modalInput",
  components:{
    // DatePicker,
    Multiselect,
  },
  props: [],
  data() {
    return {
      list_element_gigi: [
        {value: 11, text: '11'},
        {value: 12, text: '12'},
        {value: 13, text: '13'},
        {value: 14, text: '14'},
        {value: 15, text: '15'},
        {value: 16, text: '16'},
        {value: 17, text: '17'},
        {value: 18, text: '18'},
        {value: 21, text: '21'},
        {value: 23, text: '23'},
        {value: 24, text: '24'},
        {value: 25, text: '25'},
        {value: 26, text: '26'},
        {value: 27, text: '27'},
        {value: 28, text: '28'},
      ],
      list_diagnosa: [
        {value: 1, text: 'diagnosa 1'},
        {value: 2, text: 'diagnosa 2'},
        {value: 3, text: 'diagnosa 3'},
        {value: 4, text: 'diagnosa 4'},
        {value: 5, text: 'diagnosa 5'},
      ],
      list_prosedur: [
        {value: 1, text: 'prosdur 1'},
        {value: 2, text: 'prosdur 2'},
        {value: 3, text: 'prosdur 3'},
        {value: 4, text: 'prosdur 4'},
        {value: 5, text: 'prosdur 5'},
      ],
      list_occilusi: [
        {value: 1, text: 'Normal Bite'},
        {value: 2, text: 'Cross Bite'},
        {value: 3, text: 'Step Bite'},
      ],
      list_torus_palatines: [
        {value: 1, text: 'Tidak Ada'},
        {value: 2, text: 'Kecil'},
        {value: 3, text: 'Sedang'},
        {value: 4, text: 'Besar'},
        {value: 5, text: 'Multiple'},
      ],
      list_torus_mandibularis: [
        {value: 1, text: 'Tidak Ada'},
        {value: 2, text: 'Sisi Kiri'},
        {value: 3, text: 'Sisi Kanan'},
        {value: 4, text: 'Kedua Sisi'},
      ],
      list_palatum: [
        {value: 1, text: 'Dalam'},
        {value: 2, text: 'Sedang'},
        {value: 2, text: 'Rendah'},
      ],
      list_diastema: [
        {value: 1, text: 'Ya'},
        {value: 2, text: 'Tidak'},
      ],
      list_gigi_anomaly: [
        {value: 1, text: 'Ya'},
        {value: 2, text: 'Tidak'},
      ],
      is_data: {
        element_gigi: null,
        diagnosa: null,
        prosedur: null,
        occilusi: null,
        torus_palatines: null,
        torus_mandibularis: null,
        palatum: null,
        diastema: null,
        gigi_anomaly: null,
        jelaskan: '',
        lain_lain: ''
      },
      // },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      element_gigi: { required },
      diagnosa: { required },
      prosedur: { required },
      occilusi: { required },
      torus_palatines: { required },
      torus_mandibularis: { required },
      palatum: { required },
      diastema: { required },
      gigi_anomaly: { required },
      jelaskan: { required },
      lain_lain: { required },
    },
  },
  async mounted() {
    // const vm = this
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      if(vm.busy){
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: "BERHASIL MENDAFTARKAN ODONTOGRAM",
          showing: true,
        });
        this.$bvModal.hide("modal-odontogram");
        this.resetModal();
      }else{
        axios
          .post(ipBackend + "msAsuransi/register", vm.is_data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN ODONTOGRAM",
                showing: true,
              });
              this.$bvModal.hide("modal-odontogram");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        element_gigi: null,
        diagnosa: null,
        prosedur: null,
        occilusi: null,
        torus_palatines: null,
        torus_mandibularis: null,
        palatum: null,
        diastema: null,
        gigi_anomaly: null,
        jelaskan: '',
        lain_lain: ''
      };
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
