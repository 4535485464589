<template>
<div>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card class="mb-2" header-tag="header" header-bg-variant="primary">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Pelayanan Diagnosa</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="6" sm="12" md="12" lg="6">
              <b-card class="mb-2" header-tag="header" header-bg-variant="success">
                <template #header>
                  <h5 class="mb-0" style="color: #fff">
                    <strong> DETAIL KUNJUNGAN</strong>
                  </h5>
                </template>
                <b-row>
                  <b-col cols="3">TANGGAL DAFTAR</b-col>
                  <b-col cols="9">: {{$moment(dataHeader.tanggal_daftar).format('LLLL') || '-'}}</b-col>
                  <b-col cols="3">NO. URUT</b-col>
                  <b-col cols="9">: {{dataHeader.no_urut || '-'}}</b-col>
                  <b-col cols="3">NO. KUNJUNGAN</b-col>
                  <b-col cols="9">: {{dataHeader.no_kunjungan || '-'}}</b-col>
                  <b-col cols="3">POLI</b-col>
                  <b-col cols="9">: {{dataHeader.nama_poli || '-'}}</b-col>
                  <b-col cols="3">FASKES PERTAMA</b-col>
                  <b-col cols="9">: {{$findKey(listProviderPeserta, dataHeader.kdppk, false, 'kdProvider', 'nmProvider') || '-'}}</b-col>
                  <b-col cols="3">KONDISI</b-col>
                  <b-col cols="9">: {{dataHeader.kunjungan_sakit ? 'Sakit' : 'Sehat'}}</b-col>
                  <b-col cols="3">KELUHAN</b-col>
                  <b-col cols="9">: {{dataHeader.keluhan || '-'}}</b-col>
                  <!-- <b-col cols="3">JENIS PEMBAYARAN / NO PESERTA</b-col>
                  <b-col cols="9">: {{dataHeader.jenis_pembayaran || '-'}} / {{dataHeader.no_peserta || '-'}}</b-col> -->
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="6" sm="12" md="12" lg="6">
              <b-card class="mb-2" header-tag="header" header-bg-variant="info">
                <template #header>
                  <h5 class="mb-0" style="color: #fff">
                    <strong>DETAIL PASIEN</strong>
                  </h5>
                </template>
                <b-row>
                  <b-col cols="3">NO. RM</b-col>
                  <b-col cols="9">: {{dataHeader.no_rm || '-'}}</b-col>
                  <b-col cols="3">NO. BPJS</b-col>
                  <b-col cols="9">: {{dataHeader.no_kartu || '-'}}</b-col>
                  <b-col cols="3">NIK</b-col>
                  <b-col cols="9">: {{dataHeader.nik || '-'}}</b-col>
                  <b-col cols="3">NAMA PASIEN</b-col>
                  <b-col cols="9">: {{dataHeader.nama_lengkap || '-'}}</b-col>
                  <b-col cols="3">TTL</b-col>
                  <b-col cols="9">: {{dataHeader.tempat_lahir || '-'}}, {{$moment(dataHeader.tanggal_lahir).format('LL') || '-'}}</b-col>
                  <b-col cols="3">JK / UMUR</b-col>
                  <b-col cols="9">: {{$findKey($store.state.data_static.jenis_kelamin, dataHeader.jenis_kelamin) || '-'}} / {{$moment().diff($moment(dataHeader.tanggal_lahir, 'YYYY'), 'years')}}</b-col>
                  <b-col cols="3">GOLONGAN DARAH</b-col>
                  <b-col cols="9">: {{dataHeader.nama_golongan_darah || '-'}}</b-col>
                  <!-- <b-col cols="3">NAMA AYAH</b-col>
                  <b-col cols="9">: {{dataHeader.nama_ayah || '-'}}</b-col>
                  <b-col cols="3">NAMA IBU</b-col>
                  <b-col cols="9">: {{dataHeader.nama_ibu || '-'}}</b-col> -->
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12"> <KajianAwal :kajian_awal="kajian_awal"/> </b-col>
            <b-col cols="12">
              <CButton 
                @click="collapseRiwayatKunjunganSebelumnya = !collapseRiwayatKunjunganSebelumnya" 
                color="warning text-white"
                class="mb-1 mt-3 w-100"
              >
                RIWAYAT KUNJUNGAN SEBELUMNYA
              </CButton>
              <CCollapse :show="collapseRiwayatKunjunganSebelumnya">
                <b-card class="m-0">
                  <c-row class="w-100 m-0">
                    <c-col cols="6">
                      The body of the box
                    </c-col>
                  </c-row>
                </b-card>
              </CCollapse>
              <b-card class="m-0 mt-3">
                <c-row class="w-100 m-0">
                  <c-col cols="2">
                    <CButton 
                      color="info"
                      class="w-full"
                    >
                      Kirim Ke PCARE
                    </CButton>
                    <CButton 
                      color="light"
                      class="w-full ml-2"
                    >
                      Cetak Rujukan
                    </CButton>
                  </c-col>
                  <c-col cols="6"></c-col>
                  <c-col cols="4">
                    <div class="h-100 d-flex align-content-center flex-wrap justify-content-end ">
                      0114U1630316Y000003
                    </div>
                  </c-col>
                </c-row>
              </b-card>
            </b-col>
            <b-col cols="12" class="mt-3">
              <CTabs variant="tabs" :fill="true" :active-tab="0">
                <CTab title="REKAM MEDIS">
                  <RekamMedis />
                </CTab>
                <CTab title="ICD X">
                  <ICDX />
                </CTab>
                <CTab title="TINDAKAN">
                  <Tindakan />
                </CTab>
                <CTab title="PERMINTAAN LABOR">
                  <PermintaanLabor />
                </CTab>
                <CTab title="PERMINTAAN OBAT">
                  <PermintaanObat />
                </CTab>
                <CTab title="KONDISI AKHIR">
                  <KondisiAkhir />
                </CTab>
              </CTabs>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import KajianAwal from './kajian_awal.vue'
import RekamMedis from './rekam_medis/rekam_medis.vue'
import ICDX from './icd_x/icd_x.vue'
import Tindakan from './tindakan/tindakan.vue'
import PermintaanLabor from './permintaan_labor/permintaan_labor.vue'
import PermintaanObat from './permintaan_obat/permintaan_obat.vue'
import KondisiAkhir from './kondisi_akhir/kondisi_akhir.vue'
export default {
  components:{
    KajianAwal,
    RekamMedis,
    ICDX,
    Tindakan,
    PermintaanLabor,
    PermintaanObat,
    KondisiAkhir,
  },
  data() {
    return {
      listProviderPeserta: [],
      show: true,
      dataHeader: {
        tanggal_daftar: moment(),
        no_urut: '001',
        no_kunjungan: 'RJ-06/06/2023/0002',
        poli_tujuan: 'Ruang Gigi dan Mulut  [Belum Selesai]',
        jenis_pembayaran: 'Umum',
        no_peserta: '002',

        //pasien
        no_rekam_medis: '000002',
        no_peserta_bpjs: 'test'
      },
      kajian_awal: {
        //data psikologi, sosial, & ekonomi
        a_bicara_a: true,
        a_komunikasi_a: true,
        a_status_emosional_a: true,
        a_sosiologi_a: true,
        a_ekonomi_a: true,

        //pola kebiasaan / hidup
        a_nutrisi_a: true,
        a_istirahat_a: true,
        a_aktifitas_fisik_a: true,
        a_merokok_a: true,
        a_konta_Asap_rokok_a: true,
        a_minum_alkohol_a: true,
        a_jamban_sehat_a: true,
        a_sarana_air_bersih_a: true,

        //riwayat pekerjaan
        a_berhubungan_zat_berbahaya_a: true,
        a_riwayat_berpergian_terakhir_a: true,
        
        //riwayat kesehatan
        a_obat_a: true,
        a_makanan_a: true,
        a_udara_a: true,
        a_riwayat_penyakit_terdahulu_a: [],
        a_riwayat_transfusi_a: null,
        a_riwayat_operasi_a: null,
        a_riwayat_rs_a: null,
        a_riwayat_penyakit_keluarga_a: [],
        
        //assesment resoki gizi / maltutrision screening tool (mst)
        b__b: true,
      },
      collapseKajianAwal: false,
      collapseRiwayatKunjunganSebelumnya: false,
    }
  },
  mounted() {
    const vm = this
    vm.getData()
    vm.getList()
  },
  methods: {
    async getData(){
      const vm = this
      console.log(vm.$route.query.id)
      if(vm.$route.query.id){
        let kunjungan = await vm.$axios('kunjungan/details_by_id/' + vm.$route.query.id)
        if(kunjungan.data.status == 200){
          console.log('kunjungan', kunjungan)
          const x = kunjungan.data.data[0]
          vm.dataHeader = {
            ...x,
          }
        }
      }else{
        vm.$router.push('./rawat_jalan')
      }
    },
    async getList(){
      const vm = this
      let listProviderPeserta = await vm.$axios.post("/integrasi_pcare/get_provider");
      vm.listProviderPeserta = listProviderPeserta.data.status == 200 ? listProviderPeserta.data.data : []
    }
  },
}
</script>