<template>
  <div>
    <CButton 
      @click="collapseKajianAwal = !collapseKajianAwal" 
      color="primary"
      class="mb-1 mt-3 w-100"
    >
      KAJIAN AWAL
    </CButton>
    <CCollapse :show="collapseKajianAwal">
      <b-card class="m-0">
        <c-row class="w-100 m-0">
          <c-col cols="6" class="m-0 p-0 mr-3">
            <b-card class="mb-2" header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>DATA PSIKOLOGIS, SOSIAL & EKONOMI</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="3">Bicara</b-col>
                <b-col cols="9" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_bicara_a" id="a_bicara_a_jelas" checked v-model="kajian_awal.a_bicara_a" :value="true">
                    <label class="form-check-label" for="a_bicara_a_jelas">
                      Jelas
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_bicara_a" id="a_bicara_a_tidak_jelas" v-model="kajian_awal.a_bicara_a" :value="false">
                    <label class="form-check-label" for="a_bicara_a_tidak_jelas">
                      Tidak Jelas
                    </label>
                  </div>
                </b-col>
                <b-col cols="3">Komunikasi</b-col>
                <b-col cols="9" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_komunikasi_a" id="a_komunikasi_a2" checked v-model="kajian_awal.a_komunikasi_a" :value="true">
                    <label class="form-check-label" for="a_komunikasi_a2">
                      Verbal
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_komunikasi_a" id="a_komunikasi_a1" v-model="kajian_awal.a_komunikasi_a" :value="false">
                    <label class="form-check-label" for="a_komunikasi_a1">
                      Non Verbal
                    </label>
                  </div>
                </b-col>
                <b-col cols="3">Status Emosional SRQ</b-col>
                <b-col cols="9" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_status_emosional_a" id="a_status_emosional_a_1" checked>
                    <label class="form-check-label" for="a_status_emosional_a_1">
                      Stabil / Tenang
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_status_emosional_a" id="a_status_emosional_a_2">
                    <label class="form-check-label" for="a_status_emosional_a_2">
                      Depresi
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_status_emosional_a" id="a_status_emosional_a_3">
                    <label class="form-check-label" for="a_status_emosional_a_3">
                      Cemas
                    </label>
                  </div>
                </b-col>
                <b-col cols="3">Sosiologi</b-col>
                <b-col cols="9" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_sosiologi_a" id="a_sosiologi_a2" checked>
                    <label class="form-check-label" for="a_sosiologi_a2">
                      Komunikatif
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_sosiologi_a" id="a_sosiologi_a1">
                    <label class="form-check-label" for="a_sosiologi_a1">
                      Non Komunikatif
                    </label>
                  </div>
                </b-col>
                <b-col cols="3">Ekonomi</b-col>
                <b-col cols="9" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_ekonomi_a" id="a_ekonomi_a2" checked>
                    <label class="form-check-label" for="a_ekonomi_a2">
                      Diatas UMR
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_ekonomi_a" id="a_ekonomi_a1">
                    <label class="form-check-label" for="a_ekonomi_a1">
                      Dibawah UMR
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="mb-2" header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>RIWAYAT PEKERJAAN</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="6">Apakah Pekerjaan pasien berhubungan dengan zat berbahaya ( misalnya : kimia, Gas dll )</b-col>
                <b-col cols="6" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_berhubungan_zat_berbahaya_a" id="a_berhubungan_zat_berbahaya_a2" checked>
                    <label class="form-check-label" for="a_berhubungan_zat_berbahaya_a2">
                      Tidak
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_berhubungan_zat_berbahaya_a" id="a_berhubungan_zat_berbahaya_a1">
                    <label class="form-check-label" for="a_berhubungan_zat_berbahaya_a1">
                      Ya
                    </label>
                  </div>
                </b-col>
                <b-col cols="6">Riwayat Bepergian Terakhir (2 minggu terakhir)</b-col>
                <b-col cols="6" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_riwayat_berpergian_terakhir_a" id="a_riwayat_berpergian_terakhir_a2" checked>
                    <label class="form-check-label" for="a_riwayat_berpergian_terakhir_a2">
                      Tidak Ada
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_riwayat_berpergian_terakhir_a" id="a_riwayat_berpergian_terakhir_a1">
                    <label class="form-check-label" for="a_riwayat_berpergian_terakhir_a1">
                      Ada
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="mb-2" header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>RIWAYAT KESEHATAN</strong>
                </h5>
              </template>
              <ol type="1" class="m-0 px-2 w-100">
                <li class="w-100">
                  RIWAYAT ALERGI
                  <b-row class="pl-2">
                    <b-col cols="5">Obat</b-col>
                    <b-col cols="7" class="d-flex align-content-center flex-wrap">
                      <div class="form-check">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="obat" id="obat2" checked>
                        <label class="form-check-label" for="obat2">
                          Tidak Ada
                        </label>
                      </div>
                      <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="obat" id="obat1">
                        <label class="form-check-label" for="obat1">
                          Ya
                        </label>
                      </div>
                    </b-col>
                    <b-col cols="5">Makanan</b-col>
                    <b-col cols="7" class="d-flex align-content-center flex-wrap">
                      <div class="form-check">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="makanan" id="makanan2" checked>
                        <label class="form-check-label" for="makanan2">
                          Tidak Ada
                        </label>
                      </div>
                      <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="makanan" id="makanan1">
                        <label class="form-check-label" for="makanan1">
                          Ya
                        </label>
                      </div>
                    </b-col>
                    <b-col cols="5">Udara</b-col>
                    <b-col cols="7" class="d-flex align-content-center flex-wrap">
                      <div class="form-check">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="udara" id="udara2" checked>
                        <label class="form-check-label" for="udara2">
                          Tidak Ada
                        </label>
                      </div>
                      <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="udara" id="udara1">
                        <label class="form-check-label" for="udara1">
                          Ya
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                </li>
                <li class="mt-2">
                  RIWAYAT PENYAKIT DAHULU
                  <b-row>
                    <b-col cols="12">
                      <b-form-group class="ml-2" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                          id="riwayat_penyakit_terdahulu"
                          v-model="kajian_awal.a_riwayat_penyakit_terdahulu_a"
                          :options="riwayat_penyakit_terdahulu"
                          :aria-describedby="ariaDescribedby"
                          name="riwayat_penyakit_terdahulu"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col cols="3">
                      Riwayat Transfusi
                    </b-col> -->
                    <b-col cols="12" class="ml-2">
                      <b-form-group label-cols-md="3">
                        <template v-slot:label>
                          Riwayat Transfusi 
                        </template>
                        <b-form-input
                          type="text"
                          v-model="kajian_awal.a_riwayat_transfusi_a"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-md="3">
                        <template v-slot:label>
                          Riwayat Operasi
                        </template>
                        <b-form-textarea
                          id="textarea"
                          placeholder=""
                          rows="3"
                          max-rows="6"
                          v-model="kajian_awal.a_riwayat_operasi_a"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group label-cols-md="3">
                        <template v-slot:label>
                          Riwayat dirawat RS
                        </template>
                        <b-form-textarea
                          id="textarea"
                          placeholder=""
                          rows="3"
                          max-rows="6"
                          v-model="kajian_awal.a_riwayat_rs_a"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </li>
                <li class="mt-2">
                  RIWAYAT PENYAKIT DALAM KELUARGA 
                  <b-row>
                    <b-col cols="12">
                      <b-form-group class="ml-2" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                          id="riwayat_penyakit_dalam_keluarga"
                          v-model="kajian_awal.a_riwayat_penyakit_dalam_keluarga_a"
                          :options="riwayat_penyakit_dalam_keluarga"
                          :aria-describedby="ariaDescribedby"
                          name="riwayat_penyakit_dalam_keluarga"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </li>
              </ol>
            </b-card>
          </c-col>
          <c-col cols="6" class="m-0 p-0">
            <b-card class="mb-2" header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>POLA KEBIASAAN / POLA HIDUP</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="4">Nutrisi</b-col>
                <b-col cols="8" class="">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_nutrisi_a" id="a_nutrisi_a2" checked>
                    <label class="form-check-label" for="a_nutrisi_a2">
                      Cukup makan sayur / buah
                    </label>
                  </div>
                  <div class="form-check ">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_nutrisi_a" id="a_nutrisi_a1">
                    <label class="form-check-label" for="a_nutrisi_a1">
                      Kurang makan sayur / buah
                    </label>
                  </div>
                  <div class="form-check ">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_nutrisi_a" id="a_nutrisi_a3">
                    <label class="form-check-label" for="a_nutrisi_a3">
                      Tidak makan sayur / buah
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Istirahat / Tidur</b-col>
                <b-col cols="8" class="">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_istirahat_a" id="a_istirahat_a2" checked>
                    <label class="form-check-label" for="a_istirahat_a2">
                      Tidak Ada Kelainan
                    </label>
                  </div>
                  <div class="form-check ">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_istirahat_a" id="a_istirahat_a1">
                    <label class="form-check-label" for="a_istirahat_a1">
                      Sulit Tidur / Insomnia
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Aktivitas Fisik</b-col>
                <b-col cols="8" class="">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_aktifitas_fisik_a" id="a_aktifitas_fisik_a2" checked>
                    <label class="form-check-label" for="a_aktifitas_fisik_a2">
                      Rutin Setiap Hari
                    </label>
                  </div>
                  <div class="form-check ">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_aktifitas_fisik_a" id="a_aktifitas_fisik_a1">
                    <label class="form-check-label" for="a_aktifitas_fisik_a1">
                      Kadang-kadang
                    </label>
                  </div>
                  <div class="form-check ">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_aktifitas_fisik_a" id="a_aktifitas_fisik_a3">
                    <label class="form-check-label" for="a_aktifitas_fisik_a3">
                      Tidak Pernah
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Merokok</b-col>
                <b-col cols="8" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_merokok_a" id="a_merokok_a2" checked>
                    <label class="form-check-label" for="a_merokok_a2">
                      Tidak Pernah
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_merokok_a" id="a_merokok_a1">
                    <label class="form-check-label" for="a_merokok_a1">
                      Ya
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Kontak Asap Rokok</b-col>
                <b-col cols="8" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_konta_Asap_rokok_a" id="a_konta_Asap_rokok_a2" checked>
                    <label class="form-check-label" for="a_konta_Asap_rokok_a2">
                      Tidak Pernah
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_konta_Asap_rokok_a" id="a_konta_Asap_rokok_a1">
                    <label class="form-check-label" for="a_konta_Asap_rokok_a1">
                      Ya
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Minum Alkohol</b-col>
                <b-col cols="8" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_minum_alkohol_a" id="a_minum_alkohol_a2" checked>
                    <label class="form-check-label" for="a_minum_alkohol_a2">
                      Tidak Pernah
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_minum_alkohol_a" id="a_minum_alkohol_a1">
                    <label class="form-check-label" for="a_minum_alkohol_a1">
                      Ya
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Jamban Sehat</b-col>
                <b-col cols="8" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_jamban_sehat_a" id="a_jamban_sehat_a2" checked>
                    <label class="form-check-label" for="a_jamban_sehat_a2">
                      Tidak Ada
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_jamban_sehat_a" id="a_jamban_sehat_a1">
                    <label class="form-check-label" for="a_jamban_sehat_a1">
                      Ada
                    </label>
                  </div>
                </b-col>
                <b-col cols="4">Sarana Air Bersih</b-col>
                <b-col cols="8" class="d-flex align-content-center flex-wrap">
                  <div class="form-check">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_sarana_air_bersih_a" id="a_sarana_air_bersih_a2" checked>
                    <label class="form-check-label" for="a_sarana_air_bersih_a2">
                      Tidak Ada
                    </label>
                  </div>
                  <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                    <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_sarana_air_bersih_a" id="a_sarana_air_bersih_a1">
                    <label class="form-check-label" for="a_sarana_air_bersih_a1">
                      Ada
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="mb-2" header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>ASSESMENT RESIKO GIZI / MALNUTRITION SCREENING TOOL (MST)</strong>
                </h5>
              </template>
              <ol type="1" class="m-0 px-2 w-100">
                <li class="w-100">
                  APAKAH PASIEN MENGALAMI PENURUNAN BB YANG TIDAK DIINGINKAN DALAM 6 BULAN TERAKHIR
                  <ol type="a" class="m-0 pl-3 w-100">
                    <li class="w-100">
                      <b-form-group label-cols-md="6">
                        <template v-slot:label>
                          Tidak ada penurunan BB
                        </template>
                        <b-form-input
                          type="text"
                          v-model="kajian_awal.a_riwayat_transfusi_a"
                        ></b-form-input>
                      </b-form-group>
                    </li>
                    <li class="w-100">
                      <b-form-group label-cols-md="6">
                        <template v-slot:label>
                          Tidak yakin/ tidak tahu/terasa baju lebih longgar
                        </template>
                        <b-form-input
                          type="text"
                          v-model="kajian_awal.a_riwayat_transfusi_a"
                        ></b-form-input>
                      </b-form-group>
                    </li>
                    <li class="w-100">
                      Jika ya, berapa penurunan BB tersebut
                      <div class="ml-2">
                        <b-form-group label-cols-md="6">
                          <template v-slot:label>
                             1-5 kg
                          </template>
                          <b-form-input
                            type="text"
                            v-model="kajian_awal.a_riwayat_transfusi_a"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="6">
                          <template v-slot:label>
                             6-10 kg
                          </template>
                          <b-form-input
                            type="text"
                            v-model="kajian_awal.a_riwayat_transfusi_a"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="6">
                          <template v-slot:label>
                             11-15 kg
                          </template>
                          <b-form-input
                            type="text"
                            v-model="kajian_awal.a_riwayat_transfusi_a"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="6">
                          <template v-slot:label>
                             > 15 kg
                          </template>
                          <b-form-input
                            type="text"
                            v-model="kajian_awal.a_riwayat_transfusi_a"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </li>
                  </ol>
                </li>
                <li class="w-100">
                  APAKAH ASUPAN MAKAN BERKURANG KARNA TIDAK NAFSU MAKAN?
                  <ol type="a" class="m-0 pl-3 w-100">
                    <li class="w-100">
                      <b-form-group label-cols-md="6">
                        <template v-slot:label>
                          Tidak
                        </template>
                        <b-form-input
                          type="text"
                          v-model="kajian_awal.a_riwayat_transfusi_a"
                        ></b-form-input>
                      </b-form-group>
                    </li>
                    <li>
                      <b-form-group label-cols-md="6">
                        <template v-slot:label>
                          Ya
                        </template>
                        <b-form-input
                          type="text"
                          v-model="kajian_awal.a_riwayat_transfusi_a"
                        ></b-form-input>
                      </b-form-group>
                    </li>
                  </ol>
                </li>
                <li class="w-100">
                  <!-- Pasien dengan diagnosis khusus -->
                  <b-row class="pl-2">
                    <b-col cols="6">Pasien dengan diagnosis khusus</b-col>
                    <b-col cols="6" class="d-flex align-content-center flex-wrap">
                      <div class="form-check">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                        <label class="form-check-label" for="flexRadioDefault2">
                          Tidak
                        </label>
                      </div>
                      <div class="ml-3 form-check d-flex align-content-center flex-wrap">
                        <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                          Ya
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                </li>
              </ol>
            </b-card>
          </c-col>
        </c-row>
      </b-card>
    </CCollapse>
  </div>
</template>
<script>
export default {
  props: [
    'kajian_awal'
  ],
  data() {
    return {
      collapseKajianAwal: false,
      riwayat_penyakit_terdahulu: [
        { text: 'Asma', value: 'asma' },
        { text: 'Diabetes Melitus', value: 'diabetes malitus' },
        { text: 'Hipertensi', value: 'hipertensi' },
        { text: 'Hepatitis A/B/C', value: 'hepatitis' },
        { text: 'Jantung', value: 'jantung' },
        { text: 'Ginjal', value: 'ginjal' },
        { text: 'Kejang', value: 'kejang' },
        { text: 'Kanker', value: 'kanker' },
        { text: 'Tuberkulosis', value: 'tuberkolosis' },
        { text: 'Gangguan Darah', value: 'gangguna darah' },
        { text: 'Gangguan Jiwa', value: 'gangguan jiwa' },
        { text: 'Stroke', value: 'stoke' },
      ],
      riwayat_penyakit_dalam_keluarga: [
        { text: 'Asma', value: 'Asma' },
        { text: 'Diabetes Melitus', value: 'Diabetes Melitus' },
        { text: 'Gangguan Jiwa', value: 'Gangguan Jiwa' },
        { text: 'Hepatitis A/B/C', value: 'Hepatitis A/B/C' },
        { text: 'Hipertensi', value: 'Hipertensi' },
        { text: 'Jantung', value: 'Jantung' },
        { text: 'Kanker', value: 'Kanker' },
        { text: 'Konsumsi Alkohol', value: 'Konsumsi Alkohol' },
        { text: 'Obesitas', value: 'Obesitas' },
        { text: 'Thalasemia', value: 'Thalasemia' },
        { text: 'Tuberkulosis', value: 'Tuberkulosis' },
        { text: 'Stroke', value: 'Stroke' },
      ],
    }
  },
}
</script>