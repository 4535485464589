<template>
  <div>
    <b-modal
      id="modal-input-permintaan-labor"
      size="lg"
      centered
      title="Tambah Data Permintaan Labor"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div class="">
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Permintaan <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              :state="checkIfValid('a_no_permintaan_a')"
              v-model="$v.is_data.a_no_permintaan_a.$model"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              id="a_tanggal_a"
              format="DD-MM-YYYY"
              v-model="$v.is_data.a_tanggal_a.$model"
              :state="checkIfValid('a_tanggal_a')"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Dokter Petunjuk <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_dokter_petunjuk_a"
              v-model="$v.is_data.a_dokter_petunjuk_a.$model"
              :options="list_dokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Dokter --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Diagnosa Sementara <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              :state="checkIfValid('a_diagnosa_sementara_a')"
              v-model="$v.is_data.a_diagnosa_sementara_a.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Informasi Tambahan <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              :state="checkIfValid('a_informasi_tambahan_a')"
              v-model="$v.is_data.a_informasi_tambahan_a.$model"
            ></b-form-textarea>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-permintaan-labor')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash"
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/id';
import "vue2-datepicker/index.css";
import moment from 'moment';
moment.locale('id');
import 'moment/locale/id';
export default {
  name: "modalInput",
  components:{
    DatePicker,
    Multiselect,
  },
  props: [],
  data() {
    return {
      list_dokter: [
        {value: 1, text: 'dr.kurnia mpd'},
        {value: 2, text: 'dr.nurul SP.OG'},
        {value: 2, text: 'dr.sari'},
      ],
      is_data: {
        a_no_permintaan_a: '123456789',
        a_tanggal_a: new Date(),
        a_dokter_petunjuk_a: '',
        a_diagnosa_sementara_a: '',
        a_informasi_tambahan_a: '',
      },
      // },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      a_no_permintaan_a: { required },
      a_tanggal_a: { required },
      a_dokter_petunjuk_a: { required },
      a_diagnosa_sementara_a: { required },
      a_informasi_tambahan_a: { required },
    },
  },
  async mounted() {
    // const vm = this
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      if(vm.busy){
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: "BERHASIL MENDAFTARKAN REKAM MEDIS",
          showing: true,
        });
        this.$bvModal.hide("modal-input-permintaan-labor");
        this.resetModal();
      }else{
        axios
          .post(ipBackend + "msAsuransi/register", vm.is_data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN REKAM MEDIS",
                showing: true,
              });
              this.$bvModal.hide("modal-input-permintaan-labor");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        a_no_permintaan_a: null,
        a_tanggal_a: new Date(),
        a_dokter_petunjuk_a: '',
        a_diagnosa_sementara_a: '',
        a_informasi_tambahan_a: '',
      };
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
