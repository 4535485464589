<template>
  <div>
    <b-modal
      id="modal-input-icd-x"
      size="lg"
      centered
      title="Tambah Data ICD-X"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div class="">
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              TANGGAL DIAGNOSA <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              id="a_tanggal_diagnosa_a"
              format="DD-MM-YYYY"
              v-model="$v.is_data.a_tanggal_diagnosa_a.$model"
              :state="checkIfValid('a_tanggal_diagnosa_a')"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              ICD-X <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_icd_x_a"
              v-model="$v.is_data.a_icd_x_a.$model"
              :options="list_icd_x"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih ICD-X --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              DESKRIPSI ICD-X <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_deskripsi_icd_x_a"
              v-model="$v.is_data.a_deskripsi_icd_x_a.$model"
              :options="list_deskripsi_idc_x"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Deskripsi ICD-X --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              JENIS DIAGNOSA <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_jenis_diagnosa_a"
              v-model="$v.is_data.a_jenis_diagnosa_a.$model"
              :options="list_jenis_diagnosa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Jenis Diagnosa --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              JENIS KASUS <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_jenis_kasus_a"
              v-model="$v.is_data.a_jenis_kasus_a.$model"
              :options="list_jenis_kasus"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Jenis Kasus --"
              size="sm"
            ></multiselect>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-icd-x')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash"
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/id';
import "vue2-datepicker/index.css";
import moment from 'moment';
moment.locale('id');
import 'moment/locale/id';
export default {
  name: "modalInput",
  components:{
    DatePicker,
    Multiselect,
  },
  props: [],
  data() {
    return {
      list_icd_x: [
        {value: 1, text: 'A00.1 / Cholera due to vibro cholerae, biovar cholerae'},
        {value: 2, text: 'A01.0 / Thyphoid fever'},
        {value: 3, text: 'A02.1 / Salmonela septicaemina'},
        {value: 4, text: 'A05.1 / Botulism'},
      ],
      list_deskripsi_idc_x: [
        {value: 1, text: 'A00.1 / Cholera due to vibro cholerae, biovar cholerae'},
        {value: 2, text: 'A01.0 / Thyphoid fever'},
        {value: 3, text: 'A02.1 / Salmonela septicaemina'},
        {value: 4, text: 'A05.1 / Botulism'},
      ],
      list_jenis_diagnosa: [
        {value: 1, text: 'Utama'},
        {value: 2, text: 'Sekunder'},
      ],
      list_jenis_kasus: [
        {value: 1, text: 'Baru'},
        {value: 2, text: 'Lama'},
      ],
      is_data: {
        a_tanggal_diagnosa_a: '',
        a_icd_x_a: '',
        a_deskripsi_icd_x_a: '',
        a_jenis_diagnosa_a: '',
        a_jenis_kasus_a: '',
      },
      // },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      a_tanggal_diagnosa_a: { required },
      a_icd_x_a: { required },
      a_deskripsi_icd_x_a: { required },
      a_jenis_diagnosa_a: { required },
      a_jenis_kasus_a: { required },
    },
  },
  async mounted() {
    const vm = this
    vm.is_data.a_tanggal_diagnosa_a = new Date()
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      if(vm.busy){
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: "BERHASIL MENDAFTARKAN ICD-X",
          showing: true,
        });
        this.$bvModal.hide("modal-input-icd-x");
        this.resetModal();
      }else{
        axios
          .post(ipBackend + "msAsuransi/register", vm.is_data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN ICD-X",
                showing: true,
              });
              this.$bvModal.hide("modal-input-icd-x");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        a_tanggal_diagnosa_a: '',
        a_icd_x_a: '',
        a_deskripsi_icd_x_a: '',
        a_jenis_diagnosa_a: '',
        a_jenis_kasus_a: '',
      };
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
