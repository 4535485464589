<template>
  <b-card>
    <b-form-group label-cols-md="3">
      <template v-slot:label>
        Status Pasien <span class="text-danger">*</span>
      </template>
      <multiselect
        id="status_pasien"
        v-model="$v.is_data.status_pasien.$model"
        :options="list_status"
        :multiple="false"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        label="text"
        track-by="value"
        placeholder="-- Pilih Status --"
        size="sm"
      ></multiselect>
    </b-form-group>
    <!-- <hr> -->
    <b-form-group label-cols-md="3">
      <template v-slot:label>
        Tanggal Kunjungan <span class="text-danger">*</span>
      </template>
      <date-picker
        style="width: 100%"
        id="tanggal_kunjungan_selanjutnya"
        format="DD-MM-YYYY"
        v-model="$v.is_data.tanggal_kunjungan_selanjutnya.$model"
        :state="checkIfValid('tanggal_kunjungan_selanjutnya')"
      ></date-picker>
    </b-form-group>
    <b-form-group label-cols-md="3">
      <template v-slot:label>
        Keterangan <span class="text-danger">*</span>
      </template>
      <b-form-textarea
        id="keterangan_kunjungan_selanjutnya"
        :state="checkIfValid('keterangan_kunjungan_selanjutnya')"
        v-model="$v.is_data.keterangan_kunjungan_selanjutnya.$model"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group label-cols-md="3">
      <template v-slot:label>
        Status <span class="text-danger">*</span>
      </template>
      <div class="h-100 d-flex align-content-center flex-wrap">
        <div class="form-check">
          <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="status_kunjungan_selanjutnya" id="status_kunjungan_selanjutnya_1" checked v-model="is_data.status_kunjungan_selanjutnya" :value="true">
          <label class="form-check-label" for="status_kunjungan_selanjutnya_1">
            Aktif
          </label>
        </div>
        <div class="form-check ml-3">
          <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="status_kunjungan_selanjutnya" id="status_kunjungan_selanjutnya_2" v-model="is_data.status_kunjungan_selanjutnya" :value="false">
          <label class="form-check-label" for="status_kunjungan_selanjutnya_2">
            Nonaktif
          </label>
        </div>
      </div>
    </b-form-group>
    <div v-if="is_data.status_pasien && is_data.status_pasien.value == 2" class="">
      <hr>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Asal Rujukan <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="asal_rujukan_rawat_jalan"
          type="text"
          :state="checkIfValid('asal_rujukan_rawat_jalan')"
          v-model="$v.is_data.asal_rujukan_rawat_jalan.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanggal Rawat Jalan <span class="text-danger">*</span>
        </template>
        <date-picker
          style="width: 100%"
          id="tanggal_raeat_jalan"
          format="DD-MM-YYYY"
          v-model="$v.is_data.tanggal_raeat_jalan.$model"
          :state="checkIfValid('tanggal_raeat_jalan')"
        ></date-picker>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Poli Tujuan <span class="text-danger">*</span>
        </template>
        <multiselect
          id="poli_tujuan_rawat_jalan"
          v-model="$v.is_data.poli_tujuan_rawat_jalan.$model"
          :options="list_poli_tujuan"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          track-by="value"
          placeholder="-- Pilih Dokter --"
          size="sm"
        ></multiselect>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Sistem Bayar <span class="text-danger">*</span>
        </template>
        <multiselect
          id="sistem_bayar_rawat_jalan"
          v-model="$v.is_data.sistem_bayar_rawat_jalan.$model"
          :options="list_sistem_bayar"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          track-by="value"
          placeholder="-- Pilih Dokter --"
          size="sm"
        ></multiselect>
      </b-form-group>
    </div>
    <div v-if="is_data.status_pasien && is_data.status_pasien.value == 3" class="">
      <hr>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Spesialis <span class="text-danger">*</span>
        </template>
        <multiselect
          id="spesialis"
          v-model="$v.is_data.spesialis.$model"
          :options="list_spesialis"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          track-by="value"
          placeholder="-- Pilih Spesialis --"
          size="sm"
        ></multiselect>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Sub Spesialis <span class="text-danger">*</span>
        </template>
        <multiselect
          id="sub_spesialis"
          v-model="$v.is_data.sub_spesialis.$model"
          :options="list_subspesialis"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          track-by="value"
          placeholder="-- Pilih Sub Spesialis --"
          size="sm"
          :disabled="!is_data.spesialis"
        ></multiselect>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Dirujuk Ke <span class="text-danger">*</span>
        </template>
        <multiselect
          id="dirujuk_ke"
          v-model="$v.is_data.dirujuk_ke.$model"
          :options="list_rs"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          track-by="value"
          placeholder="-- Pilih RS --"
          size="sm"
          :disabled="!is_data.sub_spesialis"
        ></multiselect>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Dokter Perujuk <span class="text-danger">*</span>
        </template>
        <multiselect
          id="dokter_perujuk"
          v-model="$v.is_data.dokter_perujuk.$model"
          :options="list_dokter"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="text"
          track-by="value"
          placeholder="-- Pilih Dokter --"
          size="sm"
        ></multiselect>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanggal Dirujuk <span class="text-danger">*</span>
        </template>
        <date-picker
          style="width: 100%"
          id="tanggal_dirujuk"
          format="DD-MM-YYYY"
          v-model="$v.is_data.tanggal_dirujuk.$model"
          :state="checkIfValid('tanggal_dirujuk')"
        ></date-picker>
      </b-form-group>
      
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Keterangan <span class="text-danger">*</span>
        </template>
        <b-form-textarea
          id="keterangan_dirujuk"
          :state="checkIfValid('keterangan_dirujuk')"
          v-model="$v.is_data.keterangan_dirujuk.$model"
        ></b-form-textarea>
      </b-form-group>
    </div>
    <div class="">
      <CButton 
        color="primary"
        class="w-100"
      >
        Simpan
      </CButton>
    </div>
  </b-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/id';
import "vue2-datepicker/index.css";
import moment from 'moment';
moment.locale('id');
import 'moment/locale/id';
export default {
  components:{
    DatePicker,
    Multiselect,
  },
  props: [
  ],
  data() {
    return {
      list_dokter: [
        {value: 1, text: 'dr.kurnia mpd'},
        {value: 2, text: 'dr.nurul SP.OG'},
        {value: 3, text: 'dr.sari'},
      ],
      list_spesialis: [
        {value: 1, text: 'ANAK'},
      ],
      list_subspesialis: [
        {value: 1, text: 'Anak'},
        {value: 2, text: 'Anak Alergi Imunologi'},
      ],
      list_rs: [
        {value: 1, text: 'RSUD CEMPAKA PUTIH'},
        {value: 2, text: 'RSUD KEMAYORAN'},
      ],
      list_status: [
        {value: 1, text: 'PULANG'},
        {value: 2, text: 'PINDAH POLI'},
        {value: 3, text: 'DIRUJUK'},
      ],
      list_poli_tujuan: [
        {value: 1, text: 'RUANG UMUM'},
        {value: 2, text: 'RUANG LANJUT USIA'},
        {value: 3, text: 'RUANG IBU FAN KB'},
        {value: 3, text: 'RUANG ANAK DAN REMAJA'},
        {value: 3, text: 'RUANG GIGI DAN MULUT'},
        {value: 3, text: 'RUANG PELAYANAN HAJI'},
      ],
      list_sistem_bayar: [
        {value: 1, text: 'UMUM'},
        {value: 2, text: 'BPJS'},
      ],
      is_data:{
        status_pasien: null,
        tanggal_kunjungan_selanjutnya: new Date(),
        keterangan_kunjungan_selanjutnya: '',
        status_kunjungan_selanjutnya: true,

        //pasien rawat jalan
        asal_rujukan_rawat_jalan: '',
        poli_tujuan_rawat_jalan: null,
        tanggal_raeat_jalan: new Date(),
        sistem_bayar_rawat_jalan: null,

        //pasien dirujuk
        spesialis: null,
        sub_spesialis: null,
        dokter_perujuk: null,
        dirujuk_ke: null,
        tanggal_dirujuk: new Date(),
        keterangan_dirujuk: '',
      },
      busy: false,
      button: "Simpan",
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      status_pasien: { required },
      tanggal_kunjungan_selanjutnya: { required },
      keterangan_kunjungan_selanjutnya: { required },
      status_kunjungan_selanjutnya: { required },
      //rawat jalan
      asal_rujukan_rawat_jalan: {  },
      poli_tujuan_rawat_jalan: {  },
      tanggal_raeat_jalan: {  },
      sistem_bayar_rawat_jalan: {  },
      //dirujuk
      dokter_perujuk: {  },
      spesialis: {  },
      sub_spesialis: {  },
      dirujuk_ke: {  },
      tanggal_dirujuk: {  },
      keterangan_dirujuk: {  },
    }
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
}
</script>