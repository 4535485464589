<template>
  <div>
    <b-modal
      id="modal-input-permintaan-obat"
      size="lg"
      centered
      title="Tambah Data Permintaan Obat"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div class="">
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Resep Obat <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              :state="checkIfValid('a_no_resep_obat_a')"
              v-model="$v.is_data.a_no_resep_obat_a.$model"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              id="a_tanggal_a"
              format="DD-MM-YYYY"
              v-model="$v.is_data.a_tanggal_a.$model"
              :state="checkIfValid('a_tanggal_a')"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Dokter Peresep <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_dokter_peresep_a"
              v-model="$v.is_data.a_dokter_peresep_a.$model"
              :options="list_dokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Dokter --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Alergi Obat <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              :state="checkIfValid('a_alergi_obat_a')"
              v-model="$v.is_data.a_alergi_obat_a.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Diagnosa Sementara <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              :state="checkIfValid('a_diagnosa_sementara_a')"
              v-model="$v.is_data.a_diagnosa_sementara_a.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Gangguan Ginjal <span class="text-danger">*</span>
            </template>
            <div class="h-100 d-flex align-content-center flex-wrap">
              <div class="form-check">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_gangguan_ginjal_a" id="a_gangguan_ginjal_a_1" checked v-model="is_data.a_gangguan_ginjal_a" :value="true">
                <label class="form-check-label" for="a_gangguan_ginjal_a_1">
                  Ya
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_gangguan_ginjal_a" id="a_gangguan_ginjal_a_2" v-model="is_data.a_gangguan_ginjal_a" :value="false">
                <label class="form-check-label" for="a_gangguan_ginjal_a_2">
                  Tidak
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Menyusui <span class="text-danger">*</span>
            </template>
            <div class="h-100 d-flex align-content-center flex-wrap">
              <div class="form-check">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_menyusui_a" id="a_menyusui_a_1" checked v-model="is_data.a_menyusui_a" :value="true">
                <label class="form-check-label" for="a_menyusui_a_1">
                  Ya
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_menyusui_a" id="a_menyusui_a_2" v-model="is_data.a_menyusui_a" :value="false">
                <label class="form-check-label" for="a_menyusui_a_2">
                  Tidak
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Hamil <span class="text-danger">*</span>
            </template>
            <div class="h-100 d-flex align-content-center flex-wrap">
              <div class="form-check">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_hamil_a" id="a_hamil_a_1" checked v-model="is_data.a_hamil_a" :value="true">
                <label class="form-check-label" for="a_hamil_a_1">
                  Ya
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_hamil_a" id="a_hamil_a_2" v-model="is_data.a_hamil_a" :value="false">
                <label class="form-check-label" for="a_hamil_a_2">
                  Tidak
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Resep Pasien Pulang <span class="text-danger">*</span>
            </template>
            <div class="h-100 d-flex align-content-center flex-wrap">
              <div class="form-check">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_resep_pasien_pulang_a" id="a_resep_pasien_pulang_a_1" checked v-model="is_data.a_resep_pasien_pulang_a" :value="true">
                <label class="form-check-label" for="a_resep_pasien_pulang_a_1">
                  Ya
                </label>
              </div>
              <div class="form-check ml-3">
                <input class="form-check-input" style="margin-top: 0.1em;" type="radio" name="a_resep_pasien_pulang_a" id="a_resep_pasien_pulang_a_2" v-model="is_data.a_resep_pasien_pulang_a" :value="false">
                <label class="form-check-label" for="a_resep_pasien_pulang_a_2">
                  Tidak
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Obat Racikan <span class="text-danger">*</span>
            </template>
            <multiselect
              id="a_obat_racikan_a"
              v-model="$v.is_data.a_obat_racikan_a.$model"
              :options="list_obat_racikan"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Obat Racikan --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Obat Non Racikan <span class="text-danger">*</span>
            </template>
            <multiselect
              id="obat_non_racikan"
              v-model="$v.is_data.obat_non_racikan.$model"
              :options="list_obat_non_racikan"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Obat Non Racikan --"
              size="sm"
            ></multiselect>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-permintaan-obat')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash"
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/id';
import "vue2-datepicker/index.css";
import moment from 'moment';
moment.locale('id');
import 'moment/locale/id';
export default {
  name: "modalInput",
  components:{
    DatePicker,
    Multiselect,
  },
  props: [],
  data() {
    return {
      list_dokter: [
        {value: 1, text: 'dr.kurnia mpd'},
        {value: 2, text: 'dr.nurul SP.OG'},
        {value: 2, text: 'dr.sari'},
      ],
      list_obat_racikan: [
        {value: 1, text: 'racikan a'},
        {value: 2, text: 'racikan b'},
        {value: 2, text: 'racikan c'},
      ],
      list_obat_non_racikan: [
        {value: 1, text: 'non racikan a'},
        {value: 2, text: 'non racikan b'},
        {value: 2, text: 'non racikan c'},
      ],
      is_data: {
        a_no_resep_obat_a: 'RES20230818010952 ',
        a_tanggal_a: new Date(),
        a_dokter_peresep_a: null,
        a_alergi_obat_a: '',
        a_diagnosa_sementara_a: '',
        a_gangguan_ginjal_a: false,
        a_menyusui_a: false,
        a_hamil_a: false,
        a_resep_pasien_pulang_a: false,
        a_obat_racikan_a: [],
        obat_non_racikan: [],
      },
      // },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      a_no_resep_obat_a: { required },
      a_tanggal_a: { required },
      a_dokter_peresep_a: { required },
      a_alergi_obat_a: { required },
      a_diagnosa_sementara_a: { required },
      a_gangguan_ginjal_a: { required },
      a_menyusui_a: { required },
      a_hamil_a: { required },
      a_resep_pasien_pulang_a: { required },
      a_obat_racikan_a: {  },
      obat_non_racikan: {  },
    },
  },
  async mounted() {
    // const vm = this
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      if(vm.busy){
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: "BERHASIL MENDAFTARKAN PERMINTAAN OBAT",
          showing: true,
        });
        this.$bvModal.hide("modal-input-permintaan-obat");
        this.resetModal();
      }else{
        axios
          .post(ipBackend + "msAsuransi/register", vm.is_data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN PERMINTAAN OBAT",
                showing: true,
              });
              this.$bvModal.hide("modal-input-permintaan-obat");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        a_no_resep_obat_a: 'RES20230818010952 ',
        a_tanggal_a: new Date(),
        a_dokter_peresep_a: null,
        a_alergi_obat_a: '',
        a_diagnosa_sementara_a: '',
        a_gangguan_ginjal_a: false,
        a_menyusui_a: false,
        a_hamil_a: false,
        a_resep_pasien_pulang_a: false,
        a_obat_racikan_a: [],
        obat_non_racikan: [],
      };
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
